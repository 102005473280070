import { RouteAuthContext } from '@/_shared/router/types';
import BasePlaceHolderView from '@/_shared/components/routing/BasePlaceHolderView.vue';
import InteractionsRightPanelMain from '@/timeline/InteractionsRightPanelMain.vue';
import { RouteLocationNormalized } from 'vue-router';
import useInteractionsStore from '@/_shared/store/interactions';
import ServiceCountsLeftPanel from '@/service_counts/ServiceCountsLeftPanel.vue';
import useServiceCountsStore from '@/_shared/store/serviceCounts';
import InteractionsLeftPanel from '@/timeline/components/InteractionsLeftPanel.vue';
import useMultiSelectRegister from '@/_shared/store/multiSelectRegister';

export const serviceCountRoutes = [
  {
    path: '/service_counts',
    components: {
      TopBar: BasePlaceHolderView,
    },
    beforeEnter: () => {
      useInteractionsStore().$reset();
      return true;
    },
    meta: { needSyncAngular: true },
    children: [
      {
        path: '',
        components: {
          SideBar: ServiceCountsLeftPanel,
        },
        beforeEnter: async (to: RouteLocationNormalized) => {
          useServiceCountsStore().fetchByDate(to.query.date ? new Date(to.query.date as string) : new Date());
        },
        children: [
          {
            path: '',
            name: 'serviceCountList',
            component: BasePlaceHolderView,
            meta: { isAngular: true },
          },
        ],
      },
      {
        path: ':serviceId(\\d+)/',
        components: {
          SideBar: InteractionsLeftPanel,
        },
        beforeEnter: () => {
          useMultiSelectRegister().$reset();
        },
        children: [
          {
            path: '',
            name: 'serviceCountList.timeline',
            component: BasePlaceHolderView,
            meta: { isAngular: true },
          },
          {
            path: 'interactions/:id(\\d+)',
            name: 'serviceCountList.timeline.interaction',
            component: InteractionsRightPanelMain,
            props: true,
          },
          { // HACK this is needed until we implement care plan review in Vue
            path: 'needinteractions/:id(\\d+)',
            name: 'serviceCountList.timeline.needinteraction',
            component: BasePlaceHolderView,
            props: false,
            meta: { isAngular: true },
          },
          {
            path: 'interactions/bulk_close',
            name: 'serviceCountList.timeline.bulkClose',
            component: InteractionsRightPanelMain,
            beforeEnter: () => {
              if (!useMultiSelectRegister().bulkInteractionIds.length) {
                return { name: 'serviceCountList' };
              }
              return true;
            },
          },
        ],
      },
    ],
  },
];
export const allowedServiceCountRoutes = (authContext: RouteAuthContext) => {
  const allowedRoutes: string[] = [];
  if (authContext.currentUser.carer) {
    allowedRoutes.push(
      'serviceCountList',
      'serviceCountList.timeline',
      'serviceCountList.timeline.bulkClose',
      'serviceCountList.timeline.interaction',
      // HACK this is needed until we implement care plan review in Vue
      'serviceCountList.timeline.needinteraction',
      // todo removed later no needed in vue
      'serviceCountList.timeline.interaction.documents',
      'serviceCountList.timeline.interaction.cancel',
      'serviceCountList.timeline.interaction.snooze',
    );
  }
  return allowedRoutes;
};
